<template>
  <v-app-bar
    id="app-bar"
    app
    fixed
    elevate-on-scroll
    color="white"
  >
    <!-- Replacing this by logo -->
    <!-- <vue-typer
      text="DeepReader"
      initial-action="typing"
      :pre-type-delay="500"
      :type-delay="100"
      caret-animation="smooth"
      :repeat="0"
      :erase-on-complete="false"
    /> -->
    <v-img
      src="logo.png"
      max-width="150px"
      contain
      transition="fade-transition"
    />

    <v-breadcrumbs
      :items="crumbs"
      divider="|"
      large
      class="ml-5"
    >
      <!-- <template v-slot:divider>
        <v-icon>mdi-drag-vertical-variant</v-icon>
      </template> -->
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.href"
          @click="TakeMeTo(item.href)"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-spacer />

    <span class="h3 font-weight-medium mr-2">
      Welcome
    </span>
    <strong v-html="userName" />
    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logoutCloak"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components

  // Utilities
  import Vue from 'vue'
  import goTo from 'vuetify/es5/services/goto'

  export default {
    name: 'DashboardCoreAppBar',

    data: () => ({
      crumbs: [{
                 text: 'Statistics',
                 href: '#statsRow',
               },
               {
                 text: 'Upload',
                 href: '#uploadDocCard',
               },
               {
                 text: 'Documents',
                 href: '#allDocTable',
               }],
      userName: '',
    }),

    created () {
      if (Vue.$keycloak.idTokenParsed) {
        this.userName = Vue.$keycloak.idTokenParsed.name
      } else {
        this.userName = 'Not Logged In'
      }
    },

    methods: {
      logoutCloak () {
        Vue.$keycloak.logout()
      },
      TakeMeTo (elemID) {
        goTo(elemID, { duration: 750, offset: 300 })
      },
    },
  }
</script>
